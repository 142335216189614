import { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ApiContext } from 'helper/ApiContext';
import Logger from 'helper/Logger';

const EditDialog = ({ open, setOpen, setBlockTitle, blockTitle, blockDescription, username, pipeId, nodeId }) => {

  const { api } = useContext(ApiContext);
  const [title, setTitle] = useState(blockTitle ?? "");

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    setTitle(blockTitle ?? "")
  };

  const onSaveClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    api.updateNodeDetails(pipeId, nodeId, username, title, blockDescription).then((response) => {
      setOpen(false);
      setBlockTitle(title);
      Logger.info("Successfully changed block title")
    }).catch((error) => {
      Logger.error("Error updating the block: " + JSON.stringify(error))
    })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            onSaveClick(event);
          },
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle>Edit Title</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Type the new title for the block, then click save.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="title"
            label="Title"
            fullWidth
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => handleClose(event)}>Cancel</Button>
          <Button variant='contained' type="submit">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditDialog;