import { Box } from "@mui/material";
import Logger from "helper/Logger";
import { UserContext } from "helper/UserContext";
import UserPermission from "helper/UserPermission";
import useFeatureFlags from "helper/useFeatureFlags";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ApiContext } from "../../helper/ApiContext";
import LazyLoad from '../LazyLoadComponent';
import BlockCellComponent from "./BlockCellComponent";

const DashboardComponent = React.memo(({ pipeId, usernameFromPath }) => {

    const { api } = useContext(ApiContext);
    const [pipe, setPipe] = useState({});
    const [reloadCounter, reload] = useState(0)
    const [branchMap, setBranchMap] = useState({});
    const branchMapRef = useRef({});
    const { user } = useContext(UserContext)
    const isAdmin = user?.permissions.some((p) => p.name === UserPermission.ADMIN)
    const { enableLazyLoading } = useFeatureFlags()
    const [blocksOrder, setBlocksOrder] = useState([])
    const [expandedBlocks, setExpandedBlocks] = useState([]);

    useEffect(() => {
        api.getUserPipe(pipeId, usernameFromPath)
            .then((response) => {
                const pipe = response.data
                setPipe(pipe)
                if (Object.keys(branchMapRef.current).length === 0) {
                    api.getBranchMap(pipeId, usernameFromPath)
                        .then(response => {
                            const branchData = response.data;
                            setBranchMap(branchData);
                            branchMapRef.current = branchData;
                            setBlocksOrder(calculateBlockOrder(pipe.blocks, branchMapRef.current));
                        })
                        .catch(error => {
                            Logger.error("Could not fetch branch map: " + JSON.stringify(error));
                        });
                } else {
                    setBlocksOrder(calculateBlockOrder(pipe.blocks, branchMapRef.current));
                }
            }).catch((error) => {
                Logger.error("Could not get user pipe: " + JSON.stringify(error))
            })
    }, [pipeId, reloadCounter])

    useEffect(() => {
        if (pipe && pipe.blocks) {
            setExpandedBlocks(pipe.blocks.map((block) => block.flow_id))
        }
    }, [pipe])

    const toggleBlockExpansion = (blockId) => {
        setExpandedBlocks((prevExpandedBlocks) => {
            if (prevExpandedBlocks.includes(blockId)) {
                return prevExpandedBlocks.filter((id) => id !== blockId);
            } else {
                return [...prevExpandedBlocks, blockId];
            }
        })
    }

    const onSelectionChanged = () => {
        reload(reloadCounter + 1)
    }

    const calculateBlockOrder = (blocks, branchMap) => {
        const blocksInOrderedBranches = blocks.map((block) => ({
            blockId: block.flow_id,
            branch: branchMap[block.flow_id] || block.order,
        })).sort((a, b) => a.branch - b.branch);

        let globalOrderCounter = 1;
        const orderedBlocks = blocksInOrderedBranches.map(({ blockId }) => {
            return {
                id: blockId,
                order: globalOrderCounter++,
            };
        });
        return orderedBlocks.map(({ id }) => id)
    }
    

    const blocks = useMemo(() => {
        if (pipe.blocks === undefined) 
            return []
        let fileImportBlock = pipe.blocks?.find((block) => block.blueprint.type === "import")
        if (fileImportBlock === undefined) {
            Logger.error("Could not find file import block")
            return []
        }
        let sortedBlocks = []
        let visitedBlocks = []
        let queue = [fileImportBlock]
        while (queue.length > 0) {
            let block = queue.shift()
            if (visitedBlocks.includes(block.flow_id)) 
                continue
            visitedBlocks.push(block.flow_id)
            sortedBlocks.push(block)
            let connections = pipe.connections.filter((connection) => connection.source_flow_id === block.flow_id)
            connections.forEach((connection) => {
                let targetBlock = pipe.blocks.find((block) => block.flow_id === connection.target_flow_id)
                if (targetBlock !== undefined) 
                    queue.push(targetBlock)
            })
        }
        let filteredBlocks = sortedBlocks.filter((block) => block.dashboard_configuration.view_in_dashboard)
        return filteredBlocks
    }, [pipe.blocks])

    const renderDashedLine = () => (
        <div style={{
            borderTop: '2px solid gray',
            margin: '20px 0',
            width: '100%'
        }} />
    );

    const blockCellComponent = (block, index) => {
        return enableLazyLoading ? (
            <LazyLoad
                key={index}
                component={BlockCellComponent}
                block={block}
                pipeId={pipeId}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                onSelectionChanged={onSelectionChanged}
                isAdmin={isAdmin}
            />
        ) : (
            <BlockCellComponent
                key={index}
                block={block}
                pipeId={pipeId}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                onSelectionChanged={onSelectionChanged}
                isAdmin={isAdmin}
                expanded={expandedBlocks.includes(block.flow_id)}
                toggleExpansion={() => toggleBlockExpansion(block.flow_id)}
            />
        );
    }

    const renderBlocksWithBranchSeparators = () => {
        let currentBranch = null;
        const blocksByBranch = blocks.reduce((acc, block) => {
            const blockBranch = branchMapRef.current[block.flow_id] || block.order; 
            if (!acc[blockBranch]) {
                acc[blockBranch] = [];
            }
            acc[blockBranch].push(block);
            return acc;
        }, {});
    
        Object.keys(blocksByBranch).forEach((branch) => {
            const fileImportBlock = blocksByBranch[branch].find((block) => block.blueprint.type === "import");
            let sortedBlocks = [];
            
            if (fileImportBlock) {
                let visitedBlocks = [];
                let queue = [fileImportBlock];
                while (queue.length > 0) {
                    let block = queue.shift();
                    if (visitedBlocks.includes(block.flow_id)) 
                        continue;
                    visitedBlocks.push(block.flow_id);
                    sortedBlocks.push(block);
                    
                    let connections = pipe.connections.filter((connection) => connection.source_flow_id === block.flow_id);
                    connections.forEach((connection) => {
                        let targetBlock = blocksByBranch[branch].find((block) => block.flow_id === connection.target_flow_id);
                        if (targetBlock !== undefined)
                            queue.push(targetBlock);
                    });
                }
                blocksByBranch[branch] = sortedBlocks; 
            }
        });
        return Object.keys(blocksByBranch).map((branch, branchIndex) => {
            const branchBlocks = blocksByBranch[branch];
            return (
                <React.Fragment key={branch}>
                    {branchIndex !== 0 && branch > 2 && renderDashedLine()} 
                    {branchBlocks.map((block, index) => blockCellComponent(block, index))}
                </React.Fragment>
            );
        });
    };
    

    return (
        <Box sx={{ mt: 7, flexDirection: 'column' }}>
            {renderBlocksWithBranchSeparators()}
        </Box>
    )
})

export default DashboardComponent