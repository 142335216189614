import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import BlockDetailsComponent from "components/PageComponents/BlockDetailsComponent";
import BlockPageComponent from "components/PageComponents/BlockPageComponent";
import { useIsForeignPipe } from "helper/UrlUtils";
import useFeatureFlags from "helper/useFeatureFlags";
import { useEffect, useRef, useState } from "react";

const BlockCellComponent = ({ block, pipeId, usernameFromPath, reload, onSelectionChanged, expanded, toggleExpansion }) => {

    const headerRef = useRef(null)
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const [showInteractivePlot, setShowInteractivePlot] = useState(false)
    const { enableDashboardAccordions} = useFeatureFlags()

    useEffect(() => {
        if (showInteractivePlot) {
            setShowInteractivePlot(false)
        }
    }, [reload])

    const handleClick = (event) => {
        let clickedOnHeaderOrChild = Array.from(headerRef.current.querySelectorAll("*")).includes(event.target)
        if (clickedOnHeaderOrChild)
            toggleExpansion();
    }

    const headerComponent = () => {
        return (
            <BlockDetailsComponent 
                pipeId={pipeId}
                block={block}
                usernameFromPath={usernameFromPath}
                isForeignPipe={isForeignPipe}
                isOnDashboard={true}
                showInteractivePlot={showInteractivePlot}
                reload={reload}
                onSelectionChanged={onSelectionChanged}
                onShowInteractivePlotClicked={() => {
                    setShowInteractivePlot(!showInteractivePlot)
                }}
            />
        )
    }

    const contentComponent = () => {
        return (
            <BlockPageComponent 
                pipeId={pipeId}
                nodeId={parseInt(block.flow_id)}
                usernameFromPath={usernameFromPath}
                reload={reload}
                showTable={false}
                isDashboardItem={true}
                showStaticPlot={!showInteractivePlot}
                onSelectionChanged={onSelectionChanged}
            />
        )
    }

    if (enableDashboardAccordions) {
        return (
            <div
                style={{
                    marginBottom: '10px',
                    minWidth: '96vw'
                }}
            >
                <Accordion
                    key={block.id}
                    expanded={expanded}
                    onClick={handleClick}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        ref={headerRef}
                    >
                        {headerComponent()}
                    </AccordionSummary>
                    <AccordionDetails>
                        {contentComponent()}
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    } else {
        return (
            <div 
                key={block.id} 
                style={{ 
                    marginBottom: '20px',
                    border: '1px solid black',
                    backgroundColor: 'white',
                    minWidth: '96vw'
                }}
            >
                <div style={{ borderBottom: '1px solid black', padding: '10px' }}>
                    {headerComponent()}
                </div>
                <div style={{ padding: '10px' }}>
                    {contentComponent()}
                </div>
            </div>
        )
    }
}

export default BlockCellComponent
