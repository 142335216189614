import { Delete, Edit, Visibility } from "@mui/icons-material";
import { Box, Dialog, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import DeleteDialogComponent from "components/dialogs/DeleteDialogComponent";
import EditPipeDialogComponent from "components/dialogs/EditPipeDialogComponent";
import { encodePipeId, useURLParts } from "helper/UrlUtils";
import useFeatureFlags from "helper/useFeatureFlags";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import Route from "../../helper/Route";
import { UserContext } from "../../helper/UserContext";
import UserPermission from "../../helper/UserPermission";
import DefaultPaper from "../DefaultPaper";
import { PipeItemComponent } from "../PipeItemComponent";
import { ChangeCostCentersComponent } from "./UserManagementPageComponent";

const PipesRowComponent = ({ pipes, onClick, onEditClick, onDeleteClick, onVisibilityClick }) => {
    const { user } = useContext(UserContext);
    const { currentUserIsAdmin } = useFeatureFlags()

    const tooltipContent = (pipe) => {
        const shouldShowEditOption = currentUserIsAdmin && pipe.is_template && user && pipe.user_id === user.id
        const shouldShowVisibilityOption = currentUserIsAdmin && pipe.is_template && user && pipe.user_id === user.id
        const shouldShowDeleteOption = currentUserIsAdmin

        return (
            <Stack direction="row" spacing={1}>
                {shouldShowEditOption && (
                    <IconButton onClick={() => onEditClick(pipe)}>
                        <Edit style={{ color: 'white' }} />
                    </IconButton>)}
                {shouldShowVisibilityOption && (
                    <IconButton onClick={() => onVisibilityClick(pipe)}>
                        <Visibility style={{ color: 'white' }} />
                    </IconButton>)}
                {shouldShowDeleteOption && (
                    <IconButton onClick={() => onDeleteClick(pipe)}>
                        <Delete style={{ color: 'white' }} />
                    </IconButton>)}
                {(!shouldShowDeleteOption && !shouldShowEditOption && !shouldShowVisibilityOption) && (<Typography variant="body2">Clone {pipe.name}</Typography>)}
            </Stack>
        )
    }

    return (
        <Grid container spacing={3} sx={{ mt: 2 }}>
            {pipes.map((pipe, index) => (
                <Grid item key={index}>
                    <Tooltip title={tooltipContent(pipe)} arrow disableHoverListener={pipe.name === "Create new pipe"}>
                        <Box>
                            <PipeItemComponent pipe={pipe} key={index} onClick={() => onClick(pipe)} />
                        </Box>
                    </Tooltip>
                </Grid>
            ))}
        </Grid>
    )
}

const PipesPageComponent = () => {
    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext);
    const { username } = useURLParts()
    const navigate = useNavigate();
    const [templates, setTemplates] = useState([]);
    const [pipes, setPipes] = useState([]);
    const [reloadPipesCounter, reloadPipes] = useState(0);
    const [editPipeDialogOpen, setEditPipeDialogOpen] = useState(false);
    const [pipeToEdit, setPipeToEdit] = useState(null);
    const [pipeToVisibility, setPipeToVisibility] = useState(undefined);
    const [deletePipeDialogOpen, setDeletePipeDialogOpen] = useState(false);
    const [pipeToDelete, setPipeToDelete] = useState(null);
    const [shouldShowCreatePipeSection, setShouldShowCreatePipeSection] = useState(false)
    const isAdmin = user?.permissions.some((p) => p.name === UserPermission.ADMIN)

    useEffect(() => {
        setShouldShowCreatePipeSection(user?.permissions.some((p) => p.name === UserPermission.CREATE_PIPE))
    }, [user])

    useEffect(() => {
        api.getTemplates()
            .then((response) => {
                setTemplates(response.data)
            })
            .catch((error) => {
                Logger.error("Could not get templates: " + JSON.stringify(error))
            })

        api.getUserPipes(username)
            .then((response) => {
                setPipes(response.data)
            })
            .catch((error) => {
                Logger.error("Could not get user pipes: " + JSON.stringify(error))
            })
    }, [reloadPipesCounter, user])

    const handleCreateClick = (template) => {
        if (template) {
            navigate(Route.Template + "/" + template.id)
        } else {
            // TODO: should we ask the pipe name directly?
            let name = "new pipe"
            api.createNewPipe(name)
                .then((response) => {
                    reloadPipes(reloadPipesCounter + 1)
                }).catch((error) => {
                    Logger.error("Could not create new pipe: " + JSON.stringify(error))
                })
        }
    }

    const handlePipeClick = (pipe) => {
        const pipeUrl = encodePipeId(user.username, pipe.pipe_id)
        if (isAdmin) {
            navigate(Route.Pipe + "/" + pipeUrl)
        } else {
            navigate(Route.Dashboard + "/" + pipeUrl)
        }
    }

    const onSaveEdit = (pipe, tag) => {
        if (!pipe.is_template) {
            console.log("Trying to edit non-template pipe");
            return
        }

        api.updateTemplateTag(pipe.id, tag)
            .then((response) => {
                reloadPipes(reloadPipesCounter + 1)
            }).catch((error) => {
                Logger.error("Could not update template: " + JSON.stringify(error))
            })
    }

    const onDeleteConfirmed = (pipe) => {
        if (pipe.is_template) {
            api.deleteTemplate(pipe.id)
                .then((response) => {
                    Logger.info("Template deleted: " + JSON.stringify(response.data))
                    reloadPipes(reloadPipesCounter + 1)
                }).catch((error) => {
                    Logger.error("Could not delete template: " + JSON.stringify(error))
                })
        } else {
            api.deletePipe(user.username, pipe.pipe_id)
                .then((response) => {
                    Logger.info("Pipe deleted: " + JSON.stringify(response.data))
                    reloadPipes(reloadPipesCounter + 1)
                }).catch((error) => {
                    Logger.error("Could not delete pipe: " + JSON.stringify(error))
                })
        }
    }

    return (
        <Box>
            {shouldShowCreatePipeSection && (
                <DefaultPaper>
                    <Box>
                        <Typography variant="h4">Templates</Typography>
                        <PipesRowComponent
                            pipes={isAdmin ? [{ name: "Create new pipe" }].concat(templates) : templates} // add empty pipe
                            onClick={(template) => {
                                if (template.name === "Create new pipe") {
                                    handleCreateClick()
                                } else {
                                    handleCreateClick(template)
                                }
                            }}
                            onEditClick={(template) => {
                                setEditPipeDialogOpen(true)
                                setPipeToEdit(template)
                            }}
                            onVisibilityClick={(template) => {
                                setPipeToVisibility(template)
                            }}
                            onDeleteClick={(template) => {
                                setDeletePipeDialogOpen(true)
                                setPipeToDelete(template)
                            }}
                        />
                    </Box>
                </DefaultPaper>
            )}
            <DefaultPaper additionalSx={{ mt: shouldShowCreatePipeSection ? 4 : 7 }}>
                {/* TODO: Find out why we need this box to fix the layout */}
                <Box>
                    <Typography variant="h4">Recent Pipes</Typography>
                    <PipesRowComponent
                        pipes={pipes}
                        onClick={(pipe) => {
                            handlePipeClick(pipe)
                        }}
                        onDeleteClick={(pipe) => {
                            setDeletePipeDialogOpen(true)
                            setPipeToDelete(pipe)
                        }}
                    />
                </Box>
            </DefaultPaper>
            <EditPipeDialogComponent
                open={editPipeDialogOpen}
                pipe={pipeToEdit}
                onClose={() => setEditPipeDialogOpen(false)}
                onSaveEdit={(tag) => {
                    onSaveEdit(pipeToEdit, tag)
                }}
            />
            <Dialog
                open={pipeToVisibility !== undefined}
                onClose={() => setPipeToVisibility(undefined)}
            >
                <ChangeCostCentersComponent
                    target={pipeToVisibility?.name}
                    originalCostCentersValue={pipeToVisibility?.cost_centers}
                    originalOrganizationId={pipeToVisibility?.organization_id}
                    onClose={(cost_centers, organization) => {
                        setPipeToVisibility(undefined)
                        api.updateTemplateCostCenters(pipeToVisibility?.id, cost_centers)
                            .then((response) => {
                                api.updateTemplateOrganization(pipeToVisibility?.id, organization)
                                    .then((response) => {
                                        reloadPipes(reloadPipesCounter + 1)
                                    })
                            })
                    }}
                />
            </Dialog>
            <DeleteDialogComponent
                open={deletePipeDialogOpen}
                objectName={pipeToDelete?.name}
                onClose={() => setDeletePipeDialogOpen(false)}
                onDeleteConfirmed={() => {
                    onDeleteConfirmed(pipeToDelete)
                }}
            />
        </Box>
    )
}

export default PipesPageComponent