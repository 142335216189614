import { FormControl, Select } from "@mui/material";
import { ConfigurePlotElementHeader } from './PlotComponents/ConfigurePlotSidebarComponent';

const SingleSelectConfigList = ({ config, onSelectOption, isForeignPipe, onChangeIsSynced, onChangeSortDescending, displayNone = true }) => {

    const isDisabled = () => {
        if (isForeignPipe) {
            return isForeignPipe()
        } else {
            return false
        }
    }

    return (
        <>
            <ConfigurePlotElementHeader config={config} onChangeIsSynced={onChangeIsSynced} onChangeSortDescending={onChangeSortDescending} />
            <FormControl
                style={{ boxSizing: 'border-box' }}
            >
                <Select
                    native
                    displayEmpty
                    value={config.selected_values[0]}
                    onChange={(event) => {
                        let value = event.target.value
                        onSelectOption(value)
                    }}
                    disabled={isDisabled()}
                >
                    {displayNone && <option value="" key={-1}>None</option>}
                    {config.options && config.options.map((option, index) => (
                        <option value={option} key={index}>{option}</option>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}

export default SingleSelectConfigList;