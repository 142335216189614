import { Box, Stack, Typography, Button } from "@mui/material"
import BlockPageComponent from "./BlockPageComponent"
import { useEffect, useMemo, useState } from "react"

const PlotSwapPageComponent = ({ pipeId, nodeId, usernameFromPath, reload, showTable = true, parentBlocks = [] }) => {

    const [swapCounter, setSwapCounter] = useState(0)

    let components = useMemo(() => { 
        return parentBlocks.map((block) => {
            return <BlockPageComponent
                pipeId={pipeId}
                nodeId={block.flow_id}
                usernameFromPath={usernameFromPath}
                reload={reload}
                showTable={showTable}
                isDashboardItem={true}
                showStaticPlot={true}
            />
        })
    }, [parentBlocks, pipeId, usernameFromPath, showTable])
    
    return (
        <Stack direction="column" spacing={2}>
            <Box>
                <Button variant="contained" onClick={() => {
                    setSwapCounter(swapCounter + 1)
                }}>Swap</Button>
            </Box>
            {components.map((component, index) => (
                <Box key={index} sx={{ display: (index === swapCounter % components.length ? 'block' : 'none') }}>
                    {component}
                </Box>
            ))}
        </Stack>
    )
}

export default PlotSwapPageComponent