import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import List from '@mui/material/List';
import { ConfigurePlotElementHeader } from "./PlotComponents/ConfigurePlotSidebarComponent";
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useState } from 'react';

export const MultiSelectConfigListItem = ({ option, onClick, checked, isForeignPipe = false, index, isShowTable }) => {
    
    return (
        <Draggable draggableId={option} index={index} isDragDisabled={!isShowTable}>
            {(provided) => (
                <ListItemButton
                    dense
                    onClick={onClick}
                    disabled={isForeignPipe()}
                    sx={{ ml: -2, wordWrap: 'break-word', width: '120%' }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItemIcon
                        style={{ height: 20, minWidth: 20 }}
                        sx={{ ml: -2 }}
                    >
                        <Checkbox
                            checked={checked}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText
                        style={{ margin: 0 }}
                        primary={<Typography variant='body2'>{option}</Typography>}
                    />
                </ListItemButton>
            )}
        </Draggable>
    )
}

const MultiSelectConfigList = ({ config, onSelectOption, isForeignPipe, onChangeIsSynced, onChangeSortDescending, onOptionsReorder, isShowTableBlock = false }) => {
    const [options, setOptions] = useState(config.options);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedOptions = Array.from(options);
        const [removed] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, removed);

        setOptions(reorderedOptions);

        // Now propagate the change to both `options` and `selected_values`
        onOptionsReorder(reorderedOptions, config.selected_values);
    };

    const invalidOptions = config.selected_values.filter((value) => !config.options?.includes(value))

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <ConfigurePlotElementHeader config={config} onChangeIsSynced={onChangeIsSynced} onChangeSortDescending={onChangeSortDescending} />
            </AccordionSummary>
            <AccordionDetails>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <List
                                dense
                                sx={{ mt: -3 }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {options.map((option, index) => (
                                    <MultiSelectConfigListItem
                                        option={option}
                                        onClick={() => { onSelectOption(option) }}
                                        checked={config.selected_values.includes(option)}
                                        isForeignPipe={isForeignPipe}
                                        index={index}
                                        key={option}
                                        isShowTable={isShowTableBlock}
                                    />
                                ))}
                                {provided.placeholder}
                                {invalidOptions.length > 0 && <>
                                    <Typography variant="h6" color="error" sx={{ mt: 2 }}>Invalid options</Typography>
                                    {invalidOptions.map((option, index) => (
                                        <MultiSelectConfigListItem
                                            option={option}
                                            onClick={() => { onSelectOption(option) }}
                                            checked={config.selected_values.includes(option)}
                                            isForeignPipe={isForeignPipe}
                                            index={options.length + index}
                                            key={option}
                                            isShowTable={isShowTableBlock}
                                        />
                                    ))}
                                </>}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </AccordionDetails>
        </Accordion>
    )
}

export default MultiSelectConfigList;