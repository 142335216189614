import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { useContext, useState } from "react";
import { ApiContext } from "../helper/ApiContext";
import { UserContext } from "../helper/UserContext";
import UserPermission from "../helper/UserPermission";
import DropzoneComponent from "./DropzoneComponent";
import ErrorSnackbar from "./snackbars/ErrorSnackbar";
import SuccessSnackbar from "./snackbars/SuccessSnackbar";

const UploadDropzoneComponent = ({ onUploadComplete = (file) => { } }) => {

    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext)
    const [files, setFiles] = useState([]);
    let [progress, setProgress] = useState(0);
    let [message, setMessage] = useState(null);
    let [error, setError] = useState(null);
    let [fileAlreadyExists, setFileAlreadyExists] = useState(null);

    const showMessageBar = (message, success) => {
        if (success) {
            setMessage(message);
            setTimeout(() => {
                setMessage(null)
            }, 5000)
        } else {
            setError(message);
            setTimeout(() => {
                setError(null)
            }, 5000)
        }
    }

    const upload = (overwrite = false) => {
        let currentFiles = files;
        setProgress(0);

        api.uploadFiles(currentFiles, overwrite, (event) => {
            setProgress(Math.round((90 * event.loaded) / event.total))
        }).then((response) => {
            setProgress(100)
            showMessageBar("File successfully uploaded", true)
            onUploadComplete(currentFiles)
        }).catch((error) => {
            setProgress(0)
            if (error.response.status === 409 && error.response.data.error_code === "FILE_ALREADY_EXISTS") {
                setFileAlreadyExists(error.response.data.filename)
            } else {
                showMessageBar("Error uploading file", false)
            }
        });
    }

    return (
        <Box>
            <Stack direction="column" spacing={2}>
                <DropzoneComponent setFiles={(files) => {
                    setFiles(files)
                    setProgress(0)
                }} />
                <Box textAlign="center">
                    {files.length > 0 && (
                        <div className="progress mb-3">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                style={{ width: progress + "%" }}
                            >
                                {progress}%
                            </div>
                        </div>
                    )}
                    <Button
                        variant="contained"
                        sx={{ ml: "auto" }}
                        disabled={files.length === 0 || progress > 0}
                        onClick={() => upload(false)}
                    >
                        Upload
                    </Button>
                </Box>
            </Stack>
            <Dialog
                open={Boolean(fileAlreadyExists)}
                onClose={() => setFileAlreadyExists(null)}
                aria-labelledby="file-exists-dialog-title"
                aria-describedby="file-exists-dialog-description"
            >
                <DialogTitle id="file-exists-dialog-title">File Already Exists</DialogTitle>
                <DialogContent>
                    <DialogContentText id="file-exists-dialog-description">
                        A file "{fileAlreadyExists}" already exists. Do you want to overwrite it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFileAlreadyExists(null)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        setFileAlreadyExists(null)
                        upload(true)
                    }} color="primary" variant="contained" autoFocus>
                        Overwrite
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar error={error} />
            <SuccessSnackbar message={message} />
        </Box>
    )
}

export default UploadDropzoneComponent;